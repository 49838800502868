<template>
  <div class="flightsWrapper">
    <div v-if="dashboardType === 'provider'" class="availabilityDashboard availabilityDashboard--flight">
      <vue-scroll :ops="ops">
        <FlightUploadsForm/>
      </vue-scroll>
    </div>
    <div v-else class="flightUploadsDashboard js-tabBox flightUploadsDashboard--flight">
      <vue-scroll :ops="ops">
        <div class="flightUploadsDashboard__header">
          <div class="flightUploadsDashboard__title">Flight upload functionality and record of all uploads</div>
          <div class="flightUploadsDashboard__nav">
            <div class="flightUploadsDashboard__nav-item js-tabNavItem" @click="tabActivate">
              <span class="text">Current</span>
            </div>
            <div class="flightUploadsDashboard__nav-item js-tabNavItem" @click="tabActivate">
              <span class="text">Historical</span>
            </div>
          </div>
        </div>
        <div class="flightUploadsDashboard__content">
          <div class="flightUploadsDashboard__content-item js-tabContentItem">
            <FlightUploadsForm :tabIsChanged="tabIsChanged"/>
          </div>
          <div class="flightUploadsDashboard__content-item js-tabContentItem">
            <FlightUploadsForm :tabIsChanged="tabIsChanged"/>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import FlightUploadsForm from '@/components/dashboard/FlightUploadsForm/FlightUploadsForm'
  import '@/views/dashboard/common/FlightUploads/FlightUploads.styl'

  export default {
    props: ['dashboardType'],
    name: 'FlightUploads',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        scrollPanel: {
          initialScrollX: false,
          scrollingX: false,
        },
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      tabIsChanged: false,
    }),
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
        this.tabIsChanged = !this.tabIsChanged
      },
    },
    components: {
      FlightUploadsForm,
    },
  }
</script>
