<template>
  <form
    ref="form"
    class="form form--availability js-availabilityForm"
    :class="[{'loading' : loading}]"
    @submit.prevent="submitHandler"
  >
    <div class="form__left">
      <div class="form__labelGroup form__labelGroup--text">
        <label class="form__label form__label--select">
          <div class="form__caption">Aircraft number</div>
          <div class="form__field-wrapper">
            <div class="selectContainer js-select-uploads"></div>
            <a-select
              v-if="isDetail == false"
              class="form__select"
              :getPopupContainer="selectContainer"
              @change="selectChange"
              :defaultValue="'Choose from list'"
              :disabled="loading"
              style="width:100%;"
            >
              <a-select-option
                v-for="(item, index) of fleet"
                :key="index"
                :value="item.tailNumber"
              >
                {{item.tailNumber}}
              </a-select-option>
            </a-select>
            <a-select
              v-else-if="aircraft"
              class="form__select"
              :getPopupContainer="selectContainer"
              @change="selectChange"
              :defaultValue="aircraft.aircraftNumber"
              :disabled="loading"
              style="width:100%;"
            >
              <a-select-option
                v-for="(item, index) of fleet"
                :key="index"
                :value="item.tailNumber"
              >
                {{item.tailNumber}}
              </a-select-option>
            </a-select>
            <span
              v-if="!aircraft && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
<!--            <input-->
<!--              v-else-if="aircraft"-->
<!--              class="form__field"-->
<!--              type="text"-->
<!--              v-model="aircraft.aircraftNumber"-->
<!--              autocomplete="off"-->
<!--            />-->
          </div>
        </label>
        <!-- <label class="form__label form__label--number">
          <div class="form__caption">Re-routing radius:</div>
          <div class="form__field-wrapper">
            <input
              class="form__field js-numberOnly"
              type="text"
              v-model="reRouting"
              placeholder="Enter number here"
              autocomplete="off"
              :disabled="loading"
            />
          </div>
        </label> -->
        <div
          v-if="isDetail == false"
          class="form__radioGroup"
        >
          <label class="form__label form__label--radio">
            <input
              class="form__radio js-changeWay"
              type="radio"
              v-model="route"
              data-type="round"
              data-text="From-To:"
              value="round-trip"
            />
            <span class="form__radio-box">Round trip (two legs)</span>
          </label>
          <label class="form__label form__label--radio">
            <input
              class="form__radio js-changeWay"
              type="radio"
              v-model="route"
              data-type="one"
              data-text="From:"
              value="single-legs"
            />
            <span class="form__radio-box">Single legs</span>
          </label>
          <label class="form__label form__label--radio">
            <input
              class="form__radio js-changeWay"
              type="radio"
              v-model="route"
              data-type="bulk"
              data-text="From-To:"
              value="bulk-single-legs"
            />
            <span class="form__radio-box">Bulk single legs</span>
          </label>
        </div>
      </div>
      <div class="form__labelGroup form__labelGroup--locations">
        <label class="form__label form__label--text">
          <div class="form__caption">
            <span class="icon"><SvgIcon name="departures"/></span>
            <span class="text">Departure airport:</span>
          </div>
          <div class="form__field-wrapper">
            <input
              class="form__field js-typeahead"
              type="text"
              v-model="departure"
              placeholder="Enter here"
              autocomplete="off"
              :disabled="loading"
              @input="searchLocations('departure', departure)"
            />
            <div
              class="typeahead-menu typeahead-empty"
              :class="{'typeahead-open' : departureItems.length > 0}"
            >
              <div class="typeahead-dataset typeahead-dataset-types">
                <div
                  class="typeahead-suggestion typeahead-selectable"
                  v-for="item in departureItems"
                  :key="item.id"
                  @click="selectPotentialLocation('departure', item)"
                >{{item.name}}</div>
              </div>
            </div>
            <span
              v-if="!departure && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
        <label class="form__label form__label--text">
          <div class="form__caption">
            <span class="icon"><SvgIcon name="arrival"/></span>
            <span class="text">Landing airport:</span>
          </div>
          <div class="form__field-wrapper">
            <input
              class="form__field js-typeahead"
              type="text"
              v-model="landing"
              placeholder="Enter here"
              autocomplete="off"
              :disabled="loading"
              @input="searchLocations('landing', landing)"
            />
            <div
              class="typeahead-menu typeahead-empty"
              :class="{'typeahead-open' : landingItems.length > 0}"
            >
              <div class="typeahead-dataset typeahead-dataset-types">
                <div
                  class="typeahead-suggestion typeahead-selectable"
                  v-for="item in landingItems"
                  :key="item.id"
                  @click="selectPotentialLocation('landing', item)"
                >{{item.name}}</div>
              </div>
            </div>
            <span
              v-if="!landing && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
      </div>
      <div class="form__labelGroup form__labelGroup--date flightAvailability__form-date" :class="{'flightAvailability__form-date-round-trip': route !== 'single-legs'}">
        <div class="form__header">
          <div class="form__title">Available Dates:</div>
          <div
            v-if="isDetail == false"
            class="form__subtitle"
          >
            Input for one leg or for two legs, depending on inital input as round-trip or single leg. If bulk upload, select consecutive date range for recurring one-way availability.
          </div>
        </div>
        <!-- this.route = 'single-legs' -->
        <div
          v-if="route === 'single-legs'"
          class="form__label form__label--date js-list-dates"
        >
          <div class="form__caption">
            <span class="icon"><SvgIcon name="calendar"/></span>
            <span class="text">From:</span>
          </div>
          <div class="form__field-list">
            <div
              class="form__field-wrapper js-wrapper-dates"
              v-for="(item, index) of dateFormCount"
              :key="index"
            >
              <label
                class="js-item-dates form__label--date-time"
              >
                <a-date-picker
                  format="M/D/YYYY"
                  :is-range="false"
                  placeholder="click to change"
                  :showToday="false"
                  v-model="item.value"
                  :getCalendarContainer="getPickerContainer"
                  :disabled="loading"
                />
                <span
                  v-if="!item.value && isNotReadyToSubmit == true"
                  class="form__field-invalid form__field-invalid--right-side"
                  style="color: #000"
                >
                  This field is required
                </span>
              </label>
              <div class="form__remove js-remove-dates" @click="removeField(index)">
                <SvgIcon name="close"/>
              </div>
            </div>
            <div class="form__add js-add-locations" @click="addFields()">
              <div class="form__add-caption">Added max number of available dates</div>
              <div class="form__add-icon">
                <SvgIcon name="plus"/>
              </div>
            </div>
          </div>
        </div>
        <!-- this.route = 'round-trip' || 'bulk-single-legs' -->
        <label
          v-else
          class="form__label form__label--date-time"
        >
          <div class="form__caption">
            <span class="icon"><SvgIcon name="calendar"/></span>
            <span class="text">From-To:</span>
          </div>

          <div class="form__field-wrapper" :class="{'form__label--date-time-selected': dateFormRange.length > 0}">
            <a-range-picker
              format="MM/DD/YYYY"
              :placeholder="['click to change', '']"
              :separator="dateFormRange.length>0 ? '-' : ''"
              v-model="dateFormRange"
              :getCalendarContainer="getPickerContainer"
              :disabled="loading"
            />
            <span
              v-if="!dateFormRange && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
      </div>
      <div class="form__labelGroup form__labelGroup--price">
        <div class="form__title">Flight(s) price:</div>
        <label class="form__label form__label--price price">
          <div class="form__field-wrapper">
            <!-- <input
              class="form__field"
              type="text"
              v-model="price"
              v-money="money"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            /> -->
            <input
              class="form__field"
              type="text"
              v-model="price"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            />
            <span
              v-if="!price && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
      </div>
      <br>
      <label class="form__label form__label--number" >
        <div class="form__caption">Departure Re-routing radius:</div>
        <div class="form__field-wrapper">
          <input class="form__field js-numberOnly" type="text" v-model="departureReroutingRadius" name="deparutre-re-routing-radius" id="departureReroutingRadius" placeholder="Enter number here" autocomplete="off">
        </div>
      </label>
      <label class="form__label form__label--number">
        <div class="form__caption">Arrival Re-routing radius:</div>
        <div class="form__field-wrapper">
          <input class="form__field js-numberOnly" type="text" v-model="arrivalReroutingRadius" name="arrival-re-routing-radius" id="arrivalReroutingRadius" placeholder="Enter number here" autocomplete="off">
        </div>
      </label>
      <div
        v-if="isDetail == false"
        class="form__label form__label--button"
      >
        <button class="form__button" :disabled="loading">
          <Spinner/>
          <span class="text">Save</span>
        </button>
      </div>
      <div
          v-if="isDetail"
          class="form__label form__label--button"
        >
          <button class="form__button" style="margin-right: 20px;" :disabled="loading" @click="seveFlight">
            <Spinner/>
            <span class="text">Save</span>
          </button>
          <button
            class="form__button"
            type="button"
            :disabled="loading"
            @click="deleteFlight"
          >
            <Spinner />
            <span class="text">Remove</span>
          </button>
        </div>
    </div>
    <div class="form__right">
      <!-- <div class="form__labelGroup form__labelGroup--question">
        <div class="form__caption">Auction?</div>
        <div class="form__radioGroup js-auction">
          <label class="form__label form__label--radio">
            <input
              class="form__radio"
              type="radio"
              v-model="auction"
              value="yes"
            />
            <span class="form__radio-box form__radio-box--yes">Yes</span>
          </label>
          <label class="form__label form__label--radio">
            <input
              class="form__radio"
              type="radio"
              v-model="auction"
              value="no"
            />
            <span class="form__radio-box form__radio-box--no">No</span>
          </label>
        </div>
        <div class="form__priceGroup js-auction-box">
          <div class="form__priceGroup-wrapper">
            <label class="form__label form__label--price price">
              <div class="form__caption">Reserve price (starting bid):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="reservePrice"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.reservePrice.$dirty && !$v.reservePrice.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                      This field is required
                    </span>
              </div>
            </label>
            <label class="form__label form__label--price price">
              <div class="form__caption">Bidding increments:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="biddingInc"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.biddingInc.$dirty && !$v.biddingInc.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                      This field is required
                    </span>
              </div>
            </label>
            <label class="form__label form__label--price price">
              <div class="form__caption">Book-it-now price:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="bookItNow"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.bookItNow.$dirty && !$v.bookItNow.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                      This field is required
                    </span>
              </div>
            </label>
            <label class="form__label form__label--price price">
              <div class="form__caption">Auction duration (time):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="auctionTime"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.auctionTime.$dirty && !$v.auctionTime.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                      This field is required
                    </span>
              </div>
            </label>
          </div>
        </div>
      </div> -->
    </div>
  </form>
</template>

<script>
  import { aircraftsAPI } from '@/api/aircrafts'
  import { locationsAPI } from '@/api/locations'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  // import { VMoney } from 'v-money'
  import { required } from 'vuelidate/lib/validators'
  import '@/components/common/SelectCommon/SelectCommon'
  import moment from 'moment'
  import './FlightUploadsForm.styl'

  export default {
    props: ['tabIsChanged'],
    name: 'FlightUploadsForm',
    data: () => ({
      loading: false,
      aircraft: '',
      route: 'single-legs',
      reRouting: 0,
      departure: '',
      landing: '',
      // dateForm: '',
      dateFormCount: [
        {
          value: null, // as `''` results in Vue warning
        },
      ],
      dateFormRange: [],
      price: '',
      auction: 'no',
      reservePrice: '',
      biddingInc: '',
      bookItNow: '',
      auctionTime: '',
      // money: {
      //   decimal: '.',
      //   thousands: ',',
      //   prefix: '$ ',
      //   precision: 2,
      //   masked: true
      // },
      departureItems: [],
      landingItems: [],
      selectedDeparture: null,
      selectedLanding: null,
      isDetail: false,
      fetchedFleet: [],
      isNotReadyToSubmit: false,
      departureReroutingRadius: null,
      arrivalReroutingRadius: null,
    }),
    // directives: { money: VMoney },
    validations: {
      departure: { required },
      landing: { required },
      price: { required },
      reservePrice: { required },
      biddingInc: { required },
      bookItNow: { required },
      auctionTime: { required },
    },
    watch: {
      auction () {
        this.auctionMenu()
      },
      tabIsChanged () {
        this.auctionMenu()
      },
    },
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    async created () {
      // quick and dirty check
      await this.$store.dispatch('getAircrafts')
      const routeItems = this.$route.path.split('/')

      try {
        const { data } = await aircraftsAPI.getAircrafts();
        this.fetchedFleet = data
      } catch (e) {
        console.log(e)
      }

      if (
        routeItems[routeItems.length - 1] !== 'flight-uploads' &&
        routeItems[routeItems.length - 1] !== 'new'
      ) {
        this.isDetail = true;

        try {
          const { data } = await aircraftsAPI.getEmptyLegs(routeItems[routeItems.length - 1])

          this.aircraft = {...data, tailNumber: data.aircraftNumber, emptyLegId: data.id }
          this.route = data.direction === 2 ? 'single-legs' : 'round-trip'
          this.departure = data.departure
          this.selectedDeparture = {id: data.departureId}
          this.landing = data.arrival
          this.selectedLanding = {id: data.arrivalId}
          this.departureReroutingRadius = data.departureReroutingRadius
          this.arrivalReroutingRadius = data.arrivalReroutingRadius

          if (data.direction === 2) {
            this.route = 'single-legs'
            this.dateFormCount = [{ value: moment(new Date(data.departureDate)) }]
          } else { // round-trip
            this.dateFormRange = [moment(new Date(data.departureDate)), moment(new Date(data.returnDate))]
          }

          if (Number.isInteger(data.exclusiveCost)) {
            this.price = `${data.exclusiveCost}`
          } else {
            this.price = `${data.exclusiveCost.toFixed(2)}`
          }
        } catch (e) {
          console.log(e)
        }
      } else { // is not availability detail
        try {
          const { data } = await aircraftsAPI.getAircrafts();
          this.fetchedFleet = data
        } catch (e) {
          console.log(e)
        }
      }
    },
    mounted () {
      this.$root.fields.changeRadio('.form__radio')
      // setTimeout(() => {
      //   this.auctionMenu()
      // }, 0)
    },
    methods: {
      removeField (id, type) {
        this.dateFormCount = this.dateFormCount.filter((item, index) => index !== id)
      },
      addFields (type) {
        this.dateFormCount.push({ value: null })
      },
      isReadyToSubmit () {
        let isDateReady;

        if (this.route !== 'single-legs') {
          // console.log(this.dateFormRange) // `[moment(<from>), moment(<to>)]`
          isDateReady = this.dateFormRange.length === 2;
        } else {
          // console.log(this.dateFormCount) // `[{ value: moment(<date>) }, ...]`

          // `filter` returns truthy objects, so if any are invalid the resulting length won't equal the actual length
          isDateReady = this.dateFormCount.filter(e => e.value).length === this.dateFormCount.length;
        }

        return this.aircraft &&
          this.selectedDeparture &&
          this.selectedLanding &&
          isDateReady &&
          // this.price !== '$ 0.00'
          this.price !== ''
      },
      async searchLocations (locationType, query) {
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          try {
            const { data } = await locationsAPI.searchLocations({ query })

            if (locationType === 'departure') {
              that.departureItems = data
            } else {
              that.landingItems = data
            }
          } catch (e) {
            console.log(e)
          }
        }, 600)
      },
       selectPotentialLocation (locationType, item) {
        if (locationType === 'departure') {
          this.selectedDeparture = item
          this.departure = item.name

          this.departureItems = [];
        } else {
          this.selectedLanding = item
          this.landing = item.name

          this.landingItems = [];
        }
      },
      async selectChange (value) {
        const routeItems = this.$route.path.split('/')
        const aircraft = this.fetchedFleet.find(a => a.tailNumber === value);
        if (
          routeItems[routeItems.length - 1] !== 'flight-uploads' &&
          routeItems[routeItems.length - 1] !== 'new'
        ) {
          const { data } = await aircraftsAPI.getEmptyLegs(routeItems[routeItems.length - 1])
          this.aircraft = {...aircraft, tailNumber: aircraft.tailNumber, emptyLegId: data.id }
        } else {
          if (!aircraft) {
            this.aircraft = null;
            return;
          }

          this.aircraft = aircraft;
        }
      },
      selectContainer () {
        return this.$refs.form.querySelector('.js-select-uploads')
      },
      getPickerContainer (trigger) {
        return this.$refs.form
      },
      auctionMenu () {
        const item = this.$refs.form.querySelector('.js-auction-box')
        const labels = item.querySelectorAll('.form__label')

        if (this.auction === 'yes') {
          this.$root.animations.slideDown(item)

          item.addEventListener('transitionend', () => {
            labels.forEach(elem => {
              elem.style.opacity = 1
            })
          }, { once: true })
        } else {
          this.$root.animations.slideUp(item)

          labels.forEach(elem => {
            elem.style.opacity = 0
          })
        }
      },
      async submitHandler () {
        if (!this.isReadyToSubmit()) {
          console.log('Invalid fields.')
          this.isNotReadyToSubmit = true;

          return false
        }

        this.isNotReadyToSubmit = false;
        this.loading = true

        // backend endpoint takes list of `SaveEmptyLegModel`
        const emptyLegList = [];

        if (this.route === 'round-trip') {
          const data = {
            aircraftId: this.aircraft.aircraftId,
            direction: 1,
            departureAirportId: this.selectedDeparture.id,
            departureReroutingRadius: +this.departureReroutingRadius,
            arrivalReroutingRadius: +this.arrivalReroutingRadius,
            departureDate: this.dateFormRange[0].format(),
            arrivalAirportId: this.selectedLanding.id,
            outboundArrivalDate: this.dateFormRange[0].format(),
            inboundDepartureDate: this.dateFormRange[1].format(),
            returnDate: this.dateFormRange[1].format(),
            // exclusiveCost: this.price.replace(/[^0-9.-]+/g, '')
            exclusiveCost: Number(this.price),
          }

          emptyLegList.push(data)
        } else if (this.route === 'single-legs') {
          this.dateFormCount.forEach((item, index) => {
            const data = {
              aircraftId: this.aircraft.aircraftId,
              direction: 2,
              departureAirportId: this.selectedDeparture.id,
              departureReroutingRadius: +this.departureReroutingRadius,
              arrivalReroutingRadius: +this.arrivalReroutingRadius,
              departureDate: item.value.format(),
              arrivalAirportId: this.selectedLanding.id,
              outboundArrivalDate: item.value.format(),
              exclusiveCost: Number(this.price),
            }

            emptyLegList.push(data)
          })
        } else { // this.route = 'bulk-single-legs'
          const days = this.dateFormRange[1].diff(this.dateFormRange[0], 'days') + 1 // `+1` to account for final day

          for (let i = 0; i < days; i++) {
            const data = {
              aircraftId: this.aircraft.aircraftId,
              direction: 2,
              departureAirportId: this.selectedDeparture.id,
              departureReroutingRadius: +this.departureReroutingRadius,
              arrivalReroutingRadius: +this.arrivalReroutingRadius,
              departureDate: i === 0
                ? this.dateFormRange[0].format()
                : this.dateFormRange[0].clone().add(i, 'days').format(),
              arrivalAirportId: this.selectedLanding.id,
              outboundArrivalDate: i === 0
                ? this.dateFormRange[0].format()
                : this.dateFormRange[0].clone().add(i, 'days').format(),
              // exclusiveCost: this.price.replace(/[^0-9.-]+/g, '')
              exclusiveCost: Number(this.price),
            }

            emptyLegList.push(data)
          }
        }

        try {
          await aircraftsAPI.addEmptyLegs(emptyLegList);

          this.$router.push('/provider-dashboard/flight-uploads');
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      },
      async seveFlight () {
        if (!this.isReadyToSubmit()) {
          console.log('Invalid fields.')
          this.isNotReadyToSubmit = true;

          return false
        }

        this.isNotReadyToSubmit = false;
        this.loading = true

        // backend endpoint takes list of `SaveEmptyLegModel`
        const emptyLegList = [];

        if (this.route === 'round-trip') {
          const data = {
            aircraftId: this.aircraft.aircraftId,
            direction: 1,
            departureAirportId: this.selectedDeparture.id,
            departureReroutingRadius: +this.departureReroutingRadius,
            arrivalReroutingRadius: +this.arrivalReroutingRadius,
            departureDate: this.dateFormRange[0].format(),
            arrivalAirportId: this.selectedLanding.id,
            outboundArrivalDate: this.dateFormRange[0].format(),
            inboundDepartureDate: this.dateFormRange[1].format(),
            returnDate: this.dateFormRange[1].format(),
            // exclusiveCost: this.price.replace(/[^0-9.-]+/g, '')
            exclusiveCost: Number(this.price),
          }

          emptyLegList.push(data)
        } else if (this.route === 'single-legs') {
          this.dateFormCount.forEach((item, index) => {
            const data = {
              aircraftId: this.aircraft.aircraftId,
              direction: 2,
              departureAirportId: this.selectedDeparture.id,
              departureReroutingRadius: +this.departureReroutingRadius,
              arrivalReroutingRadius: +this.arrivalReroutingRadius,
              departureDate: item.value.format(),
              arrivalAirportId: this.selectedLanding.id,
              outboundArrivalDate: item.value.format(),
              exclusiveCost: Number(this.price),
            }

            emptyLegList.push(data)
          })
        } else { // this.route = 'bulk-single-legs'
          const days = this.dateFormRange[1].diff(this.dateFormRange[0], 'days') + 1 // `+1` to account for final day

          for (let i = 0; i < days; i++) {
            const data = {
              aircraftId: this.aircraft.aircraftId,
              direction: 2,
              departureAirportId: this.selectedDeparture.id,
              departureReroutingRadius: +this.departureReroutingRadius,
              arrivalReroutingRadius: +this.arrivalReroutingRadius,
              departureDate: i === 0
                ? this.dateFormRange[0].format()
                : this.dateFormRange[0].clone().add(i, 'days').format(),
              arrivalAirportId: this.selectedLanding.id,
              outboundArrivalDate: i === 0
                ? this.dateFormRange[0].format()
                : this.dateFormRange[0].clone().add(i, 'days').format(),
              // exclusiveCost: this.price.replace(/[^0-9.-]+/g, '')
              exclusiveCost: Number(this.price),
            }

            emptyLegList.push(data)
          }
        }

        try {
          await aircraftsAPI.addEmptyLegs([{...emptyLegList[0], emptyLegId: this.aircraft.emptyLegId}]);

          this.$router.push('/provider-dashboard/flight-uploads');
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      },
      async deleteFlight () {
        this.loading = true

        try {
          await aircraftsAPI.removeEmptyLegs({ emptyLegId: this.aircraft.emptyLegId})

          this.$router.push('/provider-dashboard/flight-uploads')
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
